import React, { useEffect } from "react";
import { ZoomMtg } from "@zoomus/websdk";

let meetConfig = {
  apiKey: 'zdBzzfTARiGo8YKmGB0L5Q',
  apiSecret: 'e9nVbIZH2xp6znVFjEl7PH7ZxJvC1Ce3nIbd',
  meetingNumber: "8975838812",
  userName: "Amar",
  userEmail: "corp@godebate.live",
  passWord: "eTNML2hmT1ZaT3VrKzFLb0ordHZCQT09",
  role: 0,
};

function Zoom() {

  console.log(ZoomMtg, "Zoom")

  function joinMeeting(signature, meetConfig) {
    ZoomMtg.init({
      leaveUrl: "https://debatenow.trigma.in/upcoming_debates",
      isSupportAV: true,
      success: function (success) {
        console.log("Init Success ", success);
        ZoomMtg.join({
          meetingNumber: meetConfig.meetingNumber,
          userName: meetConfig.userName,
          signature: signature,
          apiKey: meetConfig.apiKey,
          passWord: meetConfig.passWord,

          success: (success) => {
            console.log(success);
          },

          error: (error) => {
            console.log(error);
          },
        });
      },
    });
  }
  useEffect(() => {
    //ZoomMtg.setZoomJSLib("node_modules/@zoomus/websdk/dist/lib", "/av");
    ZoomMtg.setZoomJSLib("https://source.zoom.us/1.8.3/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();

    /**
     * You should not visible api secret key on frontend
     * Signature must be generated on server
     * https://marketplace.zoom.us/docs/sdk/native-sdks/web/essential/signature
     */
    ZoomMtg.generateSignature({
      meetingNumber: meetConfig.meetingNumber,
      apiKey: meetConfig.apiKey,
      apiSecret: meetConfig.apiSecret,
      role: meetConfig.role,
      success: function (res) {
        console.log("res", res);

        setTimeout(() => {
          joinMeeting(res.result, meetConfig);
        }, 1000);
      },
    });
  }, []);

  return <div>Zoom Testing</div>;
}

export default Zoom;